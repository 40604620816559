// extracted by mini-css-extract-plugin
export var errorLabel = "style-module--error-label--6b0d9";
export var ghost = "style-module--ghost--ccddf";
export var input = "style-module--input--a9dd0";
export var inputDescription = "style-module--input-description--db6c0";
export var inputLabel = "style-module--input-label--65117";
export var inputWrapper = "style-module--input-wrapper--3965f";
export var long = "style-module--long--d45b8";
export var visible = "style-module--visible--1b0d8";
export var visibleElement = "style-module--visible-element--3d55f";
export var wrapper = "style-module--wrapper--38478";