import React from 'react'

// Style
import {
  link,
  title,
  wrapper,
  button,
  button0,
  buttonContainer,
} from './style.module.scss'

type Props = {
  onClick?: () => void
}

const AuthModal: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={wrapper}>
      <div className={title}>Base de datos</div>
      <div className={title}>Cómic colombiano 2024</div>
      <div>
        En alianza con la comunidad de autores de cómic nacional, nos
        encontramos realizando un esfuerzo mancomunado para recolectar la mayor
        cantidad de información sobre el escenario actual del cómic colombiano.
        <br />
        <br />
        La información recolectada se usará para:
        <br />
        <ul>
          <li>Realizar un censo de autores de comic nacional.</li>
          <li>
            Generar una base de datos que nos permita obtener resultados
            estadísticos del estado actual del cómic colombiano: trayectoria,
            alcance, crecimiento, evolución, etc.
          </li>
          <li>
            Generar una base datos robusta y completa para suministrarla al
            Ministerio de Cultura a través nuestros consejeros distritales.
          </li>
          <li>
            Organizar el montaje de la exposición{' '}
            <b>
              <i>“Salón de la Fama del Cómic Colombiano”</i>
            </b>{' '}
            en SOFA 2024 y de la página Web{' '}
            <b>
              <i>“Línea del tiempo de los 100 años del cómic en Colombia”</i>
            </b>
          </li>
          <li>
            Actualizar la información en las páginas web{' '}
            <a
              href="https://www.comiccolombiano.com/"
              target='="_blank'
              className={link}
            >
              www.comiccolombiano.com
            </a>{' '}
            y{' '}
            <a
              href="https://bogotacomicsblog.blogspot.com/"
              target='="_blank'
              className={link}
            >
              bogotacomicsblog.blogspot.com
            </a>
            .
          </li>
          <li>
            Actualizar la información en el artículo en Wikipedia con respecto
            al cómic colombiano:{' '}
            <a
              href="https://es.wikipedia.org/wiki/Historieta_en_Colombia"
              target='="_blank'
              className={link}
            >
              Historieta en Colombia
            </a>
          </li>
          <li>
            Iniciar el proceso de organización para la realización de los
            Reconocimientos al Cómic Colombiano.{' '}
          </li>
          <li>
            Iniciar el proceso para gestionar festivales, exposiciones y
            diversos eventos.
          </li>
          <li>Fortalecer los procesos actuales con entidades como IDARTES.</li>
        </ul>
        <br />
        Desde varios grupos y colectividades reconocemos la importancia de la
        recolección de estos datos, e invitamos a toda la comunidad de artistas
        a trabajar en equipo y participar en este proceso.
        <br />
        <br />
        <b>
          Al llenar los formulario, permito el uso de mis datos para los
          objetivos anteriormente planteados, en la descripción de la actividad.
        </b>
      </div>
      <br/>
      <div className={buttonContainer}>
        <div className={`${button} ${button0}`} onClick={onClick}>
          Si, estoy de acuerdo.
        </div>
      </div>
    </div>
  )
}

export default AuthModal
