// extracted by mini-css-extract-plugin
export var errorLabel = "style-module--error-label--455a6";
export var ghost = "style-module--ghost--2387c";
export var input = "style-module--input--7841a";
export var inputDescription = "style-module--input-description--1ae4b";
export var inputLabel = "style-module--input-label--14276";
export var inputWrapper = "style-module--input-wrapper--08d0e";
export var long = "style-module--long--c8feb";
export var visible = "style-module--visible--ba695";
export var visibleElement = "style-module--visible-element--ca561";
export var wrapper = "style-module--wrapper--43c76";