// extracted by mini-css-extract-plugin
export var border = "style-module--border--c5537";
export var button = "style-module--button--a721f";
export var buttons = "style-module--buttons--8284e";
export var chapterGrid = "style-module--chapter-grid--69104";
export var close = "style-module--close--dbe50";
export var description = "style-module--description--45ae1";
export var descriptions = "style-module--descriptions--d3bdf";
export var expandedCell = "style-module--expanded-cell--e45ff";
export var forms = "style-module--forms--bd6f5";
export var formsLeft = "style-module--forms-left--e9bd0";
export var formsLeftLeft = "style-module--forms-left-left--6b378";
export var formsLeftRight = "style-module--forms-left-right--67067";
export var formsRight = "style-module--forms-right--4f3b9";
export var formsRightLeft = "style-module--forms-right-left--1a36a";
export var formsRightRight = "style-module--forms-right-right--b85b4";
export var grid = "style-module--grid--a0429";
export var header = "style-module--header--6def7";
export var headerNum = "style-module--header-num--17e1f";
export var hidden = "style-module--hidden--24eb6";
export var images = "style-module--images--15b15";
export var imagesContainer = "style-module--images-container--3f1f6";
export var publication = "style-module--publication--98aaf";
export var socialIcon = "style-module--social-icon--dfe1e";
export var spinner = "style-module--spinner--b1866";
export var submitButton = "style-module--submit-button--949b3";
export var subtitle = "style-module--subtitle--8f659";
export var team = "style-module--team--90d3e";
export var thumb = "style-module--thumb--84334";
export var title = "style-module--title--a4ae0";
export var trash = "style-module--trash--85da1";
export var wrapper = "style-module--wrapper--7fd92";