// extracted by mini-css-extract-plugin
export var border = "style-module--border--f9460";
export var button = "style-module--button--77e65";
export var buttons = "style-module--buttons--4d048";
export var chapterGrid = "style-module--chapter-grid--d67b7";
export var close = "style-module--close--8bfb3";
export var description = "style-module--description--d5aa7";
export var descriptions = "style-module--descriptions--d062a";
export var expandedCell = "style-module--expanded-cell--6fa1b";
export var forms = "style-module--forms--a3f09";
export var formsLeft = "style-module--forms-left--ed441";
export var formsLeftLeft = "style-module--forms-left-left--4e5f3";
export var formsLeftRight = "style-module--forms-left-right--3f516";
export var formsRight = "style-module--forms-right--33a22";
export var formsRightLeft = "style-module--forms-right-left--b53ee";
export var formsRightRight = "style-module--forms-right-right--44f39";
export var grid = "style-module--grid--aa472";
export var header = "style-module--header--590a0";
export var headerNum = "style-module--header-num--d4696";
export var hidden = "style-module--hidden--bb0c9";
export var images = "style-module--images--80ed5";
export var imagesContainer = "style-module--images-container--a0014";
export var publication = "style-module--publication--fdedb";
export var socialIcon = "style-module--social-icon--5ae45";
export var spinner = "style-module--spinner--f6835";
export var submitButton = "style-module--submit-button--4566e";
export var subtitle = "style-module--subtitle--1fbd8";
export var team = "style-module--team--6aeb6";
export var thumb = "style-module--thumb--a591f";
export var title = "style-module--title--cbc99";
export var trash = "style-module--trash--b9729";
export var wrapper = "style-module--wrapper--5984f";