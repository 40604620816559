import React, { useContext, useEffect, useState, ReactNode } from 'react'

import { v4 as uuidV4 } from 'uuid'
import { AxiosError } from 'axios'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faTrash } from '@fortawesome/free-solid-svg-icons'

// Components
import Spinner from '../../atoms/Spinner'
import UploadImage from '../../atoms/UploadImage'
import RegisterInput from '../../atoms/RegisterInput'
import RegisterCheck from '../../atoms/RegisterCheck'
import { RegisterForm } from '../../templates/Register'
import { RegisterSeries } from '../../../services/types'
import RegisterTextarea from '../../atoms/RegisterTextArea'
import RegisterAgePicker from '../../atoms/RegisterAgePicker'
import RegisterCityPicker from '../../atoms/RegisterCityPicker'
import RegisterTypePicker from '../../atoms/RegisterTypePicker'
import RegisterDemSexPicker from '../../atoms/RegisterDemSexPicker'
import RegisterAuthorPicker from '../../atoms/RegisterAuthorPicker'
import RegisterDemAgePicker from '../../atoms/RegisterDemAgePicker'
import RegisterPublisherPicker from '../../atoms/RegisterPublisherPicker'
import RegisterDistributionPicker from '../../atoms/RegisterDistributionPicker'

// Context
import ModalContext from '../../../context/Modal/Modal.context'
import RegisterContext from '../../../context/Register/Register.context'
import RegisterSeriesPicker from '../../atoms/RegisterSeriesPicker'

// Services
import { postSeries } from '../../../services/register'

// Style
import {
  team,
  close,
  trash,
  forms,
  title,
  hidden,
  header,
  images,
  imagesContainer,
  button,
  buttons,
  wrapper,
  spinner,
  subtitle,
  chapterGrid,
  descriptions,
  expandedCell,
  submitButton,
} from './style.module.scss'

interface Props {
  succsessModal: ReactNode
  active: boolean
  closeForm: () => void
  openForm: (form: RegisterForm) => void
}

const FormChapter: React.FC<Props> = ({
  succsessModal,
  active,
  closeForm,
  openForm,
}) => {
  const [publicationId, setPublicationId] = useState<string>('')
  const [disable, setDisable] = useState(false)

  const [comicUUID, setComicUUID] = useState([uuidV4(), uuidV4()])
  const [authors, setAuthors] = useState<string[]>([])

  const [city, setCity] = useState<string | null>(null)
  const [cover, setCover] = useState<string | null>(null)
  const [image, setImage] = useState<string | null>(null)
  const [demAge, setDemAge] = useState<string | null>(null)
  const [demSex, setDemSex] = useState<string | null>(null)
  const [pSeries, setPSeries] = useState<string | null>(null)
  const [typePub, setTypePub] = useState<string | null>(null)
  const [ageRange, setAgeRange] = useState<number | null>(null)
  const [publisher, setPublisher] = useState<string | null>(null)
  const [distribution, setDistribution] = useState<number | null>(null)

  const { openModal } = useContext(ModalContext)
  const { updateSeries } = useContext(RegisterContext)

  const methods = useForm<RegisterSeries>({
    mode: 'onTouched',
  })
  const {
    reset,
    control,
    register,
    getValues,
    clearErrors,
    formState: { isValid, errors },
  } = methods

  const {
    fields: tFields,
    append: tAppend,
    remove: tRemove,
  } = useFieldArray({
    control,
    name: 'team',
  })

  useEffect(() => {
    setPublicationId(uuidV4())
    clearErrors()

    // Load local data
    if (window) {
      const localSeries = window.localStorage.getItem('chapter')
      if (localSeries) {
        const publications = JSON.parse(localSeries)
        reset(publications, { keepDefaultValues: true })

        setCity(publications.publications[0].city)
        setImage(publications.publications[0].image)
        setCover(publications.publications[0].cover)
        setAgeRange(publications.publications[0].age)
        setPSeries(publications.publications[0].series)
        setDemSex(publications.publications[0].demSex)
        setDemAge(publications.publications[0].demAge)
        setPublisher(publications.publications[0].publisher)
        setTypePub(publications.publications[0].typePublication)
        setDistribution(publications.publications[0].distribution)
        setAuthors(publications.auxAuthors?.map((a: any) => a?.author) ?? [])
      }
    }
    // Auto-save
    const interval = setInterval(() => {
      if (window) {
        window.localStorage.setItem('chapter', JSON.stringify(getValues()))
      }
    }, 30000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  const addAthor = async (data: any) => {
    data.publications = data.publications.map((p: any) => {
      for (let key of Object.keys(p)) {
        if (data[key] === '') {
          data[key] = undefined
        }
      }
      p.type = p.typePublication
      p.id = publicationId
      return p
    })

    data.team = data.team.map((t: any) => ({
      ...t,
      publication: publicationId,
    }))

    data.series = undefined
    data.genres = undefined
    data.auxChapter = undefined
    data.auxAuthors = undefined

    try {
      await postSeries(data)
      await updateSeries()
      reset()
      setCity(null)
      setImage(null)
      setCover(null)
      setDemAge(null)
      setDemSex(null)
      setTypePub(null)
      setAgeRange(null)
      setPublisher(null)
      setDistribution(null)
      setAuthors([])
      openModal(succsessModal)
      setPublicationId(uuidV4())
      setComicUUID([uuidV4(), uuidV4()])
      if (window) {
        window.localStorage.setItem('chapter', JSON.stringify(getValues()))
      }
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const msg = error.response?.data.message
        openModal(<>Error: {msg}</>)
      }
    } finally {
      setDisable(false)
    }
  }

  const onSubmit = (data: any) => {
    setDisable(true)
    openModal(<Spinner className={spinner} />)
    addAthor(data)
  }

  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>
        Formulario de inscripción de capítulos
        <FontAwesomeIcon icon={faClose} className={close} onClick={closeForm} />
      </div>
      <div className={descriptions}>
        <i>
          Este formulario te permitirá inscribir una capítulo nuevo, de una
          serie que ya esta registrada en nuestra base de datos. Si aún no has
          inscrito la serie debes ir al opción <b>Inscribir Series</b>.Recuerda
          que cuanta más información nos proporciones, mejor será la impresión
          que los usuarios tendrán de la serie y nos permitirá realizar mejores
          análisis de la situación del cómic colombiano.
        </i>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          <div className={forms}>
            <div>
              <div className={chapterGrid}>
                <div className={imagesContainer}>
                  <div className={images}>
                    <div className={subtitle}>Portada</div>
                    <UploadImage
                      name="publications.0.cover"
                      uuid={comicUUID[0]}
                      folder="publications"
                      external={cover}
                    />
                  </div>
                  <div className={images}>
                    <div className={subtitle}>Página Interna</div>
                    <UploadImage
                      name="publications.0.image"
                      uuid={comicUUID[1]}
                      folder="publications"
                      external={image}
                    />
                  </div>
                </div>
                <div className={subtitle}>Información Publicación</div>
                <RegisterSeriesPicker
                  required
                  name="publications.0.series"
                  description="Serie de la publicación"
                  label="Serie"
                  register={register('publications.0.series', {
                    required: { value: true, message: 'Campo requerido' },
                  })}
                  error={
                    errors?.publications &&
                    errors?.publications[0]?.name?.message
                  }
                  external={pSeries}
                />
                <RegisterInput
                  required
                  description="Título de la publicación"
                  label="Título"
                  register={register('publications.0.name', {
                    required: { value: true, message: 'Campo requerido' },
                  })}
                  error={
                    errors?.publications &&
                    errors?.publications[0]?.name?.message
                  }
                />
                <RegisterInput
                  required
                  type="number"
                  description="Número de la publicación"
                  label="Número"
                  register={register('publications.0.number', {
                    required: { value: true, message: 'Campo requerido' },
                  })}
                  error={
                    errors?.publications &&
                    errors?.publications[0]?.number?.message
                  }
                />
                <RegisterTypePicker
                  required
                  name="publications.0.typePublication"
                  description="Tipo de la publicación"
                  label="Tipo de capítulo"
                  register={register('publications.0.typePublication', {
                    validate: (v) => {
                      setTypePub(v ?? '')
                      return true
                    },
                  })}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.typePublication?.message
                  }
                  external={typePub}
                />
                <RegisterPublisherPicker
                  name="publications.0.publisher"
                  description="Editorial que financió la publicación. Dejar vació si es independiente"
                  label="Editorial"
                  register={register('publications.0.publisher')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.typePublication?.message
                  }
                  external={publisher}
                />
                <RegisterDistributionPicker
                  name="publications.0.distribution"
                  description="Impreso, digital o mixto"
                  label="Tipo de distribución"
                  register={register('publications.0.distribution', {
                    validate: (v) => {
                      setDistribution(v)
                      return true
                    },
                  })}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.distribution?.message
                  }
                  external={distribution}
                />
                <RegisterInput
                  type="number"
                  description="Año en el que se realizó la primera publicación"
                  label="Año de publicación"
                  register={register('publications.0.year', {
                    min: {
                      value: 1800,
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                    max: {
                      value: new Date().getFullYear(),
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                  })}
                  error={
                    errors?.publications &&
                    errors?.publications[0]?.year?.message
                  }
                />
                <RegisterCityPicker
                  name="publications.0.city"
                  description="Ciudad de Colombia en la que se publicó por primera vez. Si es un comic internacional dejar vació"
                  label="Ciudad de publicación"
                  register={register('publications.0.city')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.city?.message
                  }
                  external={city}
                />
                <RegisterInput
                  description="ISBN de la primera publicación que tenga"
                  label="ISBN/ISSN"
                  register={register('publications.0.isbn')}
                  error={
                    errors?.publications &&
                    errors?.publications[0]?.isbn?.message
                  }
                />
                {(distribution === 3 || distribution === 4) && (
                  <>
                    <RegisterInput
                      type="number"
                      description="Copias impresas en total"
                      label="Copias impresas"
                      register={register('publications.0.copies')}
                      error={
                        errors?.publications &&
                        errors?.publications[0]?.copies?.message
                      }
                    />
                    <RegisterInput
                      type="number"
                      description="Cuantas ediciones tiene la publicación"
                      label="Numero de ediciones"
                      register={register('publications.0.reditions')}
                      error={
                        errors?.publications &&
                        errors?.publications[0]?.reditions?.message
                      }
                    />
                  </>
                )}
                {(typePub === '21568f41-41f9-11ef-9dfe-b8ca3aeea906' ||
                  typePub === '21569aab-41f9-11ef-9dfe-b8ca3aeea906') && (
                  <>
                    {typePub === '21568f41-41f9-11ef-9dfe-b8ca3aeea906' && (
                      <RegisterInput
                        description="Que capítulos incluye tu volumen compilatorio"
                        label="Capítulos"
                        register={register('publications.0.includes')}
                        error={
                          errors?.publications &&
                          errors?.publications[0]?.includes?.message
                        }
                      />
                    )}
                    <RegisterInput
                      description="Elementos adicionales de tu volumen compilatorio o edición especial"
                      label="Extras"
                      register={register('publications.0.extras')}
                      error={
                        errors?.publications &&
                        errors?.publications[0]?.extras?.message
                      }
                    />
                  </>
                )}
                <div className={subtitle}>Sinopsis del Capítulo</div>
                <div className={expandedCell}>
                  <RegisterTextarea
                    description="Breve sinopsis del capítulo. Esta es la oportunidad para presentar tu trabajo al público y captar su interés. Asegúrate de transmitir tu pasión y esencia artística de la mejor manera posible, destacando los momentos clave de tu historia y lo que hace único tu enfoque creativo."
                    register={register('publications.0.description', {
                      minLength: {
                        value: 3,
                        message: 'Debe tener mas de 3 caracteres',
                      },
                    })}
                    error={
                      errors?.publications &&
                      errors.publications[0]?.description?.message
                    }
                    center
                    justify
                  />
                </div>
                <div className={subtitle}>Equipo creativo</div>
                <div className={expandedCell}>
                  <div className={team}>
                    <div className={header}>Autor</div>
                    <div className={header}>Rol</div>
                    <div />

                    {tFields.map((a, index) => (
                      <>
                        <RegisterAuthorPicker
                          register={register(`team.${index}.author`)}
                          name={`team.${index}.author`}
                          external={authors[index]}
                        />
                        <RegisterInput
                          register={register(`team.${index}.role`)}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={trash}
                          onClick={() => {
                            tRemove(index)
                          }}
                        />
                      </>
                    ))}
                  </div>
                  <div className={buttons}>
                    <div
                      className={button}
                      onClick={() => {
                        const id = uuidV4()
                        tAppend({
                          id,
                          author: '',
                          publication: '',
                          role: '',
                        })
                      }}
                    >
                      Agregar autor
                    </div>
                    <i>Si no encuentras el autor, puedes inscribirlo aqui</i>
                    <div
                      className={button}
                      onClick={() => {
                        openForm('Author')
                      }}
                    >
                      Inscribir autor
                    </div>
                  </div>
                </div>
                <div className={subtitle}>Links de acceso</div>
                <div />
                <RegisterInput
                  description="URL de una página donde se pueda leer directamente la publicación (https://...)"
                  label="Link de lectura"
                  register={register('publications.0.link', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.link?.message
                  }
                />
                <RegisterInput
                  description="URL de una página donde se pueda comprar directamente la publicación (https://...)"
                  label="Link de venta"
                  register={register('publications.0.shop', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.shop?.message
                  }
                />
                <div className={subtitle}>Público objetivo</div>
                <div />
                <RegisterDemAgePicker
                  name="publications.0.demAge"
                  description="Rango de edad de tu público objetivo"
                  label="Edad"
                  register={register('publications.0.demAge')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.demAge?.message
                  }
                  external={demAge}
                />
                <RegisterDemSexPicker
                  name="publications.0.demSex"
                  description="Género del público objetivo"
                  label="Género"
                  register={register('publications.0.demSex')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.demSex?.message
                  }
                  external={demSex}
                />
                <div />
                <div className={subtitle}>Advertencias</div>
                <RegisterAgePicker
                  name="publications.0.age"
                  description="Edad mínima sugerida, no es necesariamente la misma del público objetivo"
                  label="Edad mínima"
                  register={register('publications.0.age')}
                  error={
                    errors?.publications && errors.publications[0]?.age?.message
                  }
                  external={ageRange}
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic tiene lenguaje fuerte"
                  label="Lenguaje fuerte"
                  register={register('publications.0.languageWarn')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.languageWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra actos de violencia, incluso si es moderada"
                  label="Violencia"
                  register={register('publications.0.violenceWarn')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.violenceWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra el consumo de drogas, cigarillos o alcohol"
                  label="Sustancias"
                  register={register('publications.0.substancesWarn')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.substancesWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra sangre"
                  label="Sangre"
                  register={register('publications.0.bloodWarn')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.bloodWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra desnudos"
                  label="Desnudos"
                  register={register('publications.0.nudeWarn')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.nudeWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra actos sexuales"
                  label="Sexo"
                  register={register('publications.0.sexWarn')}
                  error={
                    errors?.publications &&
                    errors.publications[0]?.sexWarn?.message
                  }
                  center
                  justify
                />
              </div>
            </div>
          </div>
          <input
            type="submit"
            disabled={!isValid || disable}
            className={submitButton}
            value="Inscibir"
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default FormChapter
