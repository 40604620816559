import * as React from 'react'
import type { HeadFC } from 'gatsby'

// Components
import Register from '../components/templates/Register'

const RegisterTemplate = () => (
    <Register />
)

export default RegisterTemplate

export const Head: HeadFC = () => (
  <>
    <title>Registro - Comic Colombiano</title>
    <meta
      name="description"
      content="¡Novelas gráficas, revistillas, webcómics y más! Conoce aquí el catálogo más grande de cómic colombiano y sus autores. ¡La inscripción es gratuita!"
    />
    <meta
      name="keywords"
      content="colombiano,comic,cómic,base,de,datos,comics,cómics,webcomics,webcomic,novela,grafica,gráfica,arte,revistillas,catalogo,catálogo"
    />
    <meta name="robots" content="noindex" />
    <meta name="robots" content="nofollow" />
  </>
)
