// extracted by mini-css-extract-plugin
export var anonym = "style-module--anonym--72edd";
export var close = "style-module--close--06a1a";
export var description = "style-module--description--14ee0";
export var descriptions = "style-module--descriptions--a35f0";
export var expandedCell = "style-module--expanded-cell--9e50d";
export var forms = "style-module--forms--3b2ea";
export var formsLeft = "style-module--forms-left--9f565";
export var formsLeftLeft = "style-module--forms-left-left--e0235";
export var formsLeftRight = "style-module--forms-left-right--c7f21";
export var formsRight = "style-module--forms-right--67927";
export var formsRightLeft = "style-module--forms-right-left--4096f";
export var formsRightRight = "style-module--forms-right-right--7affc";
export var grid = "style-module--grid--92702";
export var hidden = "style-module--hidden--70daa";
export var socialIcon = "style-module--social-icon--fe7ab";
export var spinner = "style-module--spinner--ad0db";
export var submitButton = "style-module--submit-button--cf1c3";
export var subtitle = "style-module--subtitle--0b0b3";
export var title = "style-module--title--b9d84";
export var wrapper = "style-module--wrapper--670ca";