// extracted by mini-css-extract-plugin
export var anonym = "style-module--anonym--31334";
export var close = "style-module--close--016d8";
export var description = "style-module--description--ddc2e";
export var descriptions = "style-module--descriptions--9470e";
export var expandedCell = "style-module--expanded-cell--58815";
export var forms = "style-module--forms--02df0";
export var formsLeft = "style-module--forms-left--d65f8";
export var formsLeftLeft = "style-module--forms-left-left--dc453";
export var formsLeftRight = "style-module--forms-left-right--1f6ca";
export var formsRight = "style-module--forms-right--d9e10";
export var formsRightLeft = "style-module--forms-right-left--dc437";
export var formsRightRight = "style-module--forms-right-right--528dd";
export var grid = "style-module--grid--5e54f";
export var hidden = "style-module--hidden--f0183";
export var socialIcon = "style-module--social-icon--33eea";
export var spinner = "style-module--spinner--4aca8";
export var submitButton = "style-module--submit-button--69a1f";
export var subtitle = "style-module--subtitle--6111f";
export var title = "style-module--title--3d6aa";
export var wrapper = "style-module--wrapper--23f76";