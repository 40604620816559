// extracted by mini-css-extract-plugin
export var border = "style-module--border--16f25";
export var button = "style-module--button--314ad";
export var buttons = "style-module--buttons--e1f53";
export var chapterGrid = "style-module--chapter-grid--77584";
export var close = "style-module--close--c9ed2";
export var description = "style-module--description--8f7f2";
export var descriptions = "style-module--descriptions--1d018";
export var expandedCell = "style-module--expanded-cell--ac1fe";
export var forms = "style-module--forms--a3fda";
export var formsLeft = "style-module--forms-left--cdb8d";
export var formsLeftLeft = "style-module--forms-left-left--8e010";
export var formsLeftRight = "style-module--forms-left-right--238d6";
export var formsRight = "style-module--forms-right--2f5e3";
export var formsRightLeft = "style-module--forms-right-left--b8ef0";
export var formsRightRight = "style-module--forms-right-right--c4f60";
export var grid = "style-module--grid--b3912";
export var header = "style-module--header--513b5";
export var headerNum = "style-module--header-num--7a792";
export var hidden = "style-module--hidden--152d9";
export var images = "style-module--images--fb804";
export var imagesContainer = "style-module--images-container--ae818";
export var mobile = "style-module--mobile--4f5fa";
export var noMobile = "style-module--no-mobile--ff217";
export var publication = "style-module--publication--798fc";
export var socialIcon = "style-module--social-icon--3310f";
export var spinner = "style-module--spinner--1b377";
export var submitButton = "style-module--submit-button--719c9";
export var subtitle = "style-module--subtitle--c064f";
export var team = "style-module--team--c369c";
export var thumb = "style-module--thumb--72d68";
export var title = "style-module--title--6ffa8";
export var trash = "style-module--trash--15e14";
export var wrapper = "style-module--wrapper--f3ac2";