import React, { useContext, useEffect, useState, ReactNode } from 'react'

import { v4 as uuidV4 } from 'uuid'
import { AxiosError } from 'axios'
import { FormProvider, useForm } from 'react-hook-form'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Components
import Spinner from '../../atoms/Spinner'
import RegisterInput from '../../atoms/RegisterInput'
import { RegisterSofa2024 } from '../../../services/types'
import RegisterTextarea from '../../atoms/RegisterTextArea'
import RegisterAuthorPicker from '../../atoms/RegisterAuthorPicker'
import RegisterSeriesPicker from '../../atoms/RegisterSeriesPicker'
import RegisterPublisherPicker from '../../atoms/RegisterPublisherPicker'

// Context
import ModalContext from '../../../context/Modal/Modal.context'

// Services
import { postAuthor, postSofa } from '../../../services/register'

// Style
import {
  forms,
  close,
  title,
  hidden,
  wrapper,
  spinner,
  subtitle,
  chapterGrid,
  expandedCell,
  descriptions,
  submitButton,
} from './style.module.scss'
interface Props {
  succsessModal: ReactNode
  active: boolean
  closeForm: () => void
}

const FormSofa2024: React.FC<Props> = ({
  succsessModal,
  active,
  closeForm,
}) => {
  const [sofaId, setSofaId] = useState<string>('')
  const [disable, setDisable] = useState(false)
  const [author, setAuthor] = useState<string | null>(null)
  const [series, setSeries] = useState<string | null>(null)
  const [publisher, setPublisher] = useState<string | null>(null)

  const { openModal } = useContext(ModalContext)

  const methods = useForm<RegisterSofa2024>({
    mode: 'onTouched',
  })
  const {
    reset,
    register,
    getValues,
    clearErrors,
    formState: { isValid, errors },
  } = methods

  useEffect(() => {
    setSofaId(uuidV4())
    clearErrors()

    // Load local data
    if (window) {
      const localAuthor = window.localStorage.getItem('sofa')
      if (localAuthor) {
        const sofa = JSON.parse(localAuthor)
        reset(sofa, { keepDefaultValues: true })
        setAuthor(sofa.author)
        setSeries(sofa.series)
        setPublisher(sofa.publisher)
      }
    }
    // Auto-save
    const interval = setInterval(() => {
      if (window) {
        window.localStorage.setItem('sofa', JSON.stringify(getValues()))
      }
    }, 30000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  const addSofa = async (data: any) => {
    data.id = sofaId
    for (let key of Object.keys(data)) {
      if (data[key] === '') {
        data[key] = undefined
      }
    }
    try {
      const result = await postSofa(data)
      reset()
      setAuthor(null)
      setSeries(null)
      setPublisher(null)
      openModal(succsessModal)
      setSofaId(uuidV4())
      if (window) {
        window.localStorage.setItem('sofa', JSON.stringify(getValues()))
      }
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const msg = error.response?.data.message
        openModal(<>Error: {msg}</>)
      }
    } finally {
      setDisable(false)
    }
  }

  const onSubmit = (data: any) => {
    setDisable(true)
    openModal(<Spinner className={spinner} />)
    addSofa(data)
  }

  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>
        Formulario de inscripción al{' '}
        <i>"Salón de la Fama del Cómic Colombiano"</i> en el SOFA 2024
        <FontAwesomeIcon icon={faClose} className={close} onClick={closeForm} />
      </div>
      <div className={descriptions}>
        Esta actividad se trata de una exposición sobre obras, personajes y
        autores de cómic nacional la cual se va a realizar en SOFA 2024.
        <br />
        <br />
        Esta será una experiencia dirigida al público asistente a la Ciudad de
        Artistas y constará de una galería/museo con dummis en escala real de
        diversos personajes de cómic nacional. También contará con una galería
        física y virtual en donde presentaremos una "línea del tiempo", con la
        información de autores, obras, personajes, sellos editoriales y momentos
        destacados en los 100 años de historia del cómic nacional.
        <br />
        <br />
        Esta iniciativa está dirigida a toda la comunidad de autores de cómic
        colombianos, sin excepción. Todos están invitados a participar.
        <br />
        <br />
        ¡No te quedes por fuera, queremos que seas parte del "Salón de la Fama
        del Cómic Colombiano" en SOFA 2024!
        <br />
        <br />
        <b>¿Deseas ser parte de esta iniciativa?</b>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          <div className={forms}></div>
          <div className={chapterGrid}>
            <RegisterAuthorPicker
              required
              register={register('author', {
                required: { value: true, message: 'Campo requerido' },
              })}
              label={'Autor'}
              name={`author`}
              description="Persona responsable por la participación en el evento. Si son un colectivo elegir un representante"
              external={author}
            />
            <RegisterSeriesPicker
              required
              name="series"
              description="Serie principal con la que deseas participar en el evento. Si eres independiente deja este campo vació"
              label="Serie"
              register={register('series', {
                required: { value: true, message: 'Campo requerido' },
              })}
              error={errors?.series?.message}
              external={series}
            />
            <RegisterPublisherPicker
              name="publisher"
              description="Editorial con la que quieres presentarte a la actividad. Si eres independiente deja este campo vació"
              label="Editorial"
              register={register('publisher')}
              error={errors?.publisher?.message}
              external={publisher}
            />
            <RegisterInput
              description="Número de WhatsApp para que podamos comunicarnos contigo"
              label="Whatsapp"
              register={register('whatsapp')}
              error={errors.whatsapp?.message}
              required
            />
            <RegisterInput
              description="Email para que podamos comunicarnos contigo"
              label="Email"
              register={register('email')}
              error={errors.email?.message}
            />
            <RegisterInput
              type="number"
              description="¿Cuántas horas semanales dedica a su proyecto de historieta?"
              label="Horas semanales"
              register={register('workHours')}
              error={errors.workHours?.message}
              required
            />
            <div className={subtitle}>En su experiencia como autor</div>
            <div className={expandedCell}>
              <div>
                <RegisterTextarea
                  description="¿Cuáles son los principales problemas y necesidades del cómic nacional?"
                  register={register('issues', {
                    minLength: {
                      value: 3,
                      message: 'Debe tener mas de 3 caracteres',
                    },
                  })}
                  label="Problemas del cómic colombiano"
                  error={errors?.issues?.message}
                  center
                  justify
                  required
                />
              </div>
              <div>
                <RegisterTextarea
                  description="¿Qué propuestas puede plantear para solucionar dichas problemáticas y potenciar el rendimiento del cómic nacional actualmente?"
                  register={register('solutions', {
                    minLength: {
                      value: 3,
                      message: 'Debe tener mas de 3 caracteres',
                    },
                  })}
                  label="Propuestas para el cómic colombiano"
                  error={errors?.issues?.message}
                  center
                  justify
                  required
                />
              </div>
            </div>
          </div>
          <input
            type="submit"
            disabled={!isValid || disable}
            className={submitButton}
            value="Inscibirme"
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default FormSofa2024
