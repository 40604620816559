// extracted by mini-css-extract-plugin
export var border = "style-module--border--a0fb2";
export var chapterGrid = "style-module--chapter-grid--0f4fb";
export var close = "style-module--close--0722a";
export var description = "style-module--description--4a64b";
export var descriptions = "style-module--descriptions--63bc8";
export var expandedCell = "style-module--expanded-cell--cf920";
export var forms = "style-module--forms--feaa4";
export var formsLeft = "style-module--forms-left--27bd0";
export var formsLeftLeft = "style-module--forms-left-left--d782a";
export var formsLeftRight = "style-module--forms-left-right--74173";
export var formsRight = "style-module--forms-right--b0f89";
export var formsRightLeft = "style-module--forms-right-left--3a9ea";
export var formsRightRight = "style-module--forms-right-right--02e33";
export var grid = "style-module--grid--cd17f";
export var hidden = "style-module--hidden--ac01f";
export var socialIcon = "style-module--social-icon--dd0e7";
export var spinner = "style-module--spinner--30f13";
export var submitButton = "style-module--submit-button--dac30";
export var subtitle = "style-module--subtitle--db973";
export var title = "style-module--title--99288";
export var wrapper = "style-module--wrapper--f97d5";