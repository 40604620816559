import React, { useContext, useEffect, useState, ReactNode } from 'react'

import { v4 as uuidV4 } from 'uuid'
import { AxiosError } from 'axios'
import { FormProvider, useForm } from 'react-hook-form'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTiktok,
  faThreads,
  faYoutube,
  faLinkedin,
  faInstagram,
  faXTwitter,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons'

// Components
import Spinner from '../../atoms/Spinner'
import UploadImage from '../../atoms/UploadImage'
import RegisterInput from '../../atoms/RegisterInput'
import RegisterCheck from '../../atoms/RegisterCheck'
import { RegisterAuthor } from '../../../services/types'
import RegisterTextarea from '../../atoms/RegisterTextArea'
import RegisterCityPicker from '../../atoms/RegisterCityPicker'
import { authorName, normalizeText } from '../../../utils/utils'

// Context
import ModalContext from '../../../context/Modal/Modal.context'
import RegisterContext from '../../../context/Register/Register.context'

// Services
import { postAuthor } from '../../../services/register'

// Style
import {
  grid,
  forms,
  close,
  formsLeft,
  formsRight,
  title,
  hidden,
  wrapper,
  spinner,
  subtitle,
  socialIcon,
  descriptions,
  expandedCell,
  submitButton,
} from './style.module.scss'

interface Props {
  succsessModal: ReactNode
  active: boolean
  closeForm: () => void
}

const FormAuthor: React.FC<Props> = ({ succsessModal, active, closeForm }) => {
  const [authorId, setAuthorId] = useState<string>('')
  const [disable, setDisable] = useState(false)
  const [img, setImg] = useState<string | null>(null)
  const [birthplace, setBirthplace] = useState<string | null>(null)
  const [residence, setResidence] = useState<string | null>(null)

  const { authors, updateAuthors } = useContext(RegisterContext)
  const { openModal } = useContext(ModalContext)

  const methods = useForm<RegisterAuthor>({
    mode: 'onTouched',
  })
  const {
    reset,
    register,
    setError,
    getValues,
    clearErrors,
    formState: { isValid, errors },
  } = methods

  useEffect(() => {
    setAuthorId(uuidV4())
    clearErrors()

    // Load local data
    if (window) {
      const localAuthor = window.localStorage.getItem('author')
      if (localAuthor) {
        const author = JSON.parse(localAuthor)
        reset(author, { keepDefaultValues: true })
        setImg(author.image)
        setResidence(author.residence)
        setBirthplace(author.birthplace)
      }
    }
    // Auto-save
    const interval = setInterval(() => {
      if (window) {
        window.localStorage.setItem('author', JSON.stringify(getValues()))
      }
    }, 30000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  const addAthor = async (data: any) => {
    data.id = authorId
    for (let key of Object.keys(data)) {
      if (data[key] === '') {
        data[key] = undefined
      }
    }
    try {
      const result = await postAuthor(data)
      await updateAuthors()
      reset()
      setImg(null)
      setResidence(null)
      setBirthplace(null)
      openModal(succsessModal)
      setAuthorId(uuidV4())
      if (window) {
        window.localStorage.setItem('author', JSON.stringify(getValues()))
      }
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const msg = error.response?.data.message
        openModal(<>Error: {msg}</>)
      }
    } finally {
      setDisable(false)
    }
  }

  const onSubmit = (data: any) => {
    setDisable(true)
    openModal(<Spinner className={spinner} />)
    addAthor(data)
  }

  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>
        Formulario de inscripción de artistas
        <FontAwesomeIcon icon={faClose} className={close} onClick={closeForm} />
      </div>
      <div className={descriptions}>
        <i>
          Este formulario te permitirá inscribir un nuevo autor, ilustrador,
          editor o cualquier otro actor del sector del cómic colombiano.
          Recuerda que cuanta más información nos proporciones, mejor será la
          impresión que los usuarios tendrán de la serie y nos permitirá
          realizar mejores análisis de la situación del cómic colombiano.
        </i>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          <div className={forms}>
            <div className={formsLeft}>
              <div className={subtitle}>Foto del artista</div>
              <UploadImage
                name={'image'}
                uuid={authorId}
                folder={'authors'}
                external={img}
              />
            </div>
            <div className={formsRight}>
              <div className={subtitle}>Información del artista</div>
              <div className={grid}>
                <RegisterInput
                  description="Nombre y apellido del artista"
                  label="Nombre completo"
                  register={register('fullName', {
                    validate: (v, o) => {
                      if (v && o.pseudonym) {
                        //Checking for duplicates
                        const duplicated = authors.find(
                          (a) =>
                            normalizeText(authorName(v, o.pseudonym)) ===
                            normalizeText(authorName(a.fullname, a.pseudonym))
                        )
                        if (duplicated)
                          return 'Ya existe una artista con esta combinación de nombre y pseudónimo en nuestra base de datos'
                      }

                      const error =
                        Boolean(v || o.pseudonym) ||
                        'Ingresar por lo menos el nombre o el seudónimo'
                      if (typeof error === 'string') {
                        setError('pseudonym', {
                          type: 'validate',
                          message: error,
                        })
                      } else {
                        clearErrors('pseudonym')
                      }
                      return error
                    },
                  })}
                  error={errors.fullName?.message}
                />
                <RegisterInput
                  description="Seudonimo(s) que ha usado el artista"
                  label="Seudonimo"
                  register={register('pseudonym', {
                    validate: (v, o) => {
                      if (v && o.pseudonym) {
                        //Checking for duplicates
                        const duplicated = authors.find(
                          (a) =>
                            normalizeText(authorName(o.fullName, v)) ===
                            normalizeText(authorName(a.fullname, a.pseudonym))
                        )
                        if (duplicated)
                          return 'Ya existe una artista con esta combinación de nombre y seudónimo en nuestra base de datos'
                      }
                      const error =
                        Boolean(v || o.fullName) ||
                        'Ingresar por lo menos el nombre o el seudónimo'
                      if (typeof error === 'string') {
                        setError('fullName', {
                          type: 'validate',
                          message: error,
                        })
                      } else {
                        clearErrors('fullName')
                      }
                      if (o.anonymize && v) {
                        clearErrors('anonymize')
                      }
                      if (o.anonymize && !v) {
                        setError('anonymize', {
                          type: 'validate',
                          message:
                            'Si deseas anonimizar tu información debes ingresar un seudónimo',
                        })
                      }
                      return error
                    },
                  })}
                  error={errors.pseudonym?.message}
                />
                <RegisterInput
                  description="Pronombres con los que se identifica el artista"
                  label="Pronombres"
                  register={register('pronoun')}
                  error={errors.pronoun?.message}
                />
                <RegisterInput
                  description="Profesion(es) a las que se dedica el artista en el medio"
                  label="Profesión"
                  register={register('job')}
                  error={errors.job?.message}
                />
                <RegisterInput
                  description="Nivel de estudio más alto completado por el artista"
                  label="Estudios"
                  register={register('career')}
                  error={errors.career?.message}
                />
                <RegisterCityPicker
                  name="residence"
                  description="Ciudad en la que reside el artista"
                  label="Ciudad de residencia"
                  register={register('residence')}
                  error={errors.birthplace?.message}
                  external={residence}
                />
                <RegisterCityPicker
                  name="birthplace"
                  description="Ciudad en la que nació el artista"
                  label="Ciudad de nacimiento"
                  register={register('birthplace')}
                  error={errors.birthplace?.message}
                  external={birthplace}
                />
                <RegisterInput
                  description="Año en el que nació el artista"
                  label="Año de nacimiento"
                  type="number"
                  register={register('birthyear', {
                    min: {
                      value: 1800,
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                    max: {
                      value: new Date().getFullYear(),
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                  })}
                  error={errors.birthyear?.message}
                />
                <RegisterInput
                  description="Si el artista ya falleció poner el año en el que ocurrió"
                  type="number"
                  label="Año de fallecimiento"
                  register={register('deathyear', {
                    min: {
                      value: 1800,
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                    maxLength: { value: 4, message: 'Hola' },
                    max: {
                      value: new Date().getFullYear(),
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                  })}
                  error={errors.deathyear?.message}
                />

                <div />
                <RegisterCheck
                  description="Si activas esta opción tu nombre legal, fecha de nacimiento, ciudad de nacimiento y ciudad de residencia no se mostrarán al público pero podran ser usados por los organizadores para contactarte y hacer estudios demográficos"
                  label="Anonimizar"
                  register={register('anonymize', {
                    validate: (v, o) => {
                      if (v && !o.pseudonym) {
                        return 'Si deseas anonimizar tu información debes ingresar un seudónimo'
                      }
                      return true
                    },
                  })}
                  error={errors.anonymize?.message}
                  center
                  justify
                />
                <div className={expandedCell}>
                  <div className={subtitle}>Biografía</div>
                  <RegisterTextarea
                    description="Breve reseña de la trayectoria del artista. Esta es la oportunidad para presentar tu trabajo al público y captar su interés. Asegúrate de transmitir tu pasión y esencia artística de la mejor manera posible, destacando los momentos clave de tu carrera y lo que hace único tu enfoque creativo."
                    register={register('description', {
                      minLength: {
                        value: 3,
                        message: 'Debe tener mas de 3 caracteres',
                      },
                    })}
                    error={errors.description?.message}
                    center
                    justify
                  />
                </div>
                <div className={subtitle}>Redes sociales</div>
                <RegisterInput
                  description="URL al perfil de Facebook (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className={socialIcon}
                      />
                      Facebook
                    </span>
                  }
                  register={register('facebook', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.facebook?.message}
                />
                <RegisterInput
                  description="URL al perfil de Instagram (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className={socialIcon}
                      />
                      Instagram
                    </span>
                  }
                  register={register('instagram', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.instagram?.message}
                />
                <RegisterInput
                  description="URL al perfil de X (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faXTwitter}
                        className={socialIcon}
                      />
                      X (Twitter)
                    </span>
                  }
                  register={register('twitter', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.twitter?.message}
                />
                <RegisterInput
                  description="URL al perfil de YouTube (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faYoutube}
                        className={socialIcon}
                      />
                      YouTube
                    </span>
                  }
                  register={register('youtube', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.youtube?.message}
                />
                <RegisterInput
                  description="URL al perfil de TikTok (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon icon={faTiktok} className={socialIcon} />
                      TikTok
                    </span>
                  }
                  register={register('tiktok', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.tiktok?.message}
                />
                <RegisterInput
                  description="URL al perfil de Threads (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faThreads}
                        className={socialIcon}
                      />
                      Threads
                    </span>
                  }
                  register={register('threads', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.threads?.message}
                />
                <RegisterInput
                  description="URL al perfil de LinkedIn (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        className={socialIcon}
                      />
                      LinkedIn
                    </span>
                  }
                  register={register('linkedin', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.linkedin?.message}
                />
                <RegisterInput
                  description="URL a la página de Patreon del artista (https://...)"
                  label="Patreon"
                  register={register('patreon', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.patreon?.message}
                />
                <RegisterInput
                  description="URL a la página de Vaki del artista (https://...)"
                  label="Vaki"
                  register={register('vaki', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.vaki?.message}
                />
                <RegisterInput
                  description="URL a la página del artista (https://...)"
                  label="Página de internet"
                  register={register('webpage', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.webpage?.message}
                />
                <RegisterInput
                  description="E-mail de contacto"
                  label="E-mail"
                  register={register('email', {
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: 'El email no tiene el formato esperado',
                    },
                  })}
                  error={errors.email?.message}
                />
              </div>
            </div>
          </div>
          <input
            type="submit"
            disabled={!isValid || disable}
            className={submitButton}
            value="Inscibir"
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default FormAuthor
