// extracted by mini-css-extract-plugin
export var errorLabel = "style-module--error-label--2860a";
export var ghost = "style-module--ghost--7048d";
export var input = "style-module--input--198cd";
export var inputDescription = "style-module--input-description--49071";
export var inputLabel = "style-module--input-label--7da1f";
export var inputWrapper = "style-module--input-wrapper--e12f8";
export var long = "style-module--long--2fc9c";
export var visible = "style-module--visible--26f03";
export var visibleElement = "style-module--visible-element--deae2";
export var wrapper = "style-module--wrapper--55fb8";