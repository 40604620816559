// extracted by mini-css-extract-plugin
export var description = "style-module--description--0eeda";
export var spin = "style-module--spin--8ec75";
export var spinner = "style-module--spinner--fdd4c";
export var spinnerAnimation = "style-module--spinner-animation--13638";
export var thumb = "style-module--thumb--09c22";
export var upload = "style-module--upload--a842f";
export var uploadGhost = "style-module--upload-ghost--3112e";
export var uploadThumb = "style-module--upload-thumb--c95da";
export var uploadTrash = "style-module--upload-trash--6b12b";
export var wrapper = "style-module--wrapper--4d691";