import React, { useContext, useEffect, useState, ReactNode } from 'react'

import { v4 as uuidV4 } from 'uuid'
import { AxiosError } from 'axios'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faTrash } from '@fortawesome/free-solid-svg-icons'

// Components
import Spinner from '../../atoms/Spinner'
import UploadImage from '../../atoms/UploadImage'
import { normalizeText } from '../../../utils/utils'
import RegisterInput from '../../atoms/RegisterInput'
import RegisterCheck from '../../atoms/RegisterCheck'
import { RegisterForm } from '../../templates/Register'
import { RegisterSeries } from '../../../services/types'
import RegisterTextarea from '../../atoms/RegisterTextArea'
import RegisterAgePicker from '../../atoms/RegisterAgePicker'
import RegisterCityPicker from '../../atoms/RegisterCityPicker'
import RegisterTypePicker from '../../atoms/RegisterTypePicker'
import RegisterGenrePicker from '../../atoms/RegisterGenrePicker'
import RegisterDemSexPicker from '../../atoms/RegisterDemSexPicker'
import RegisterAuthorPicker from '../../atoms/RegisterAuthorPicker'
import RegisterDemAgePicker from '../../atoms/RegisterDemAgePicker'
import RegisterStatusPicker from '../../atoms/RegisterStatusPicker'
import RegisterCountryPicker from '../../atoms/RegisterCountryPicker'
import RegisterPublisherPicker from '../../atoms/RegisterPublisherPicker'
import RegisterDistributionPicker from '../../atoms/RegisterDistributionPicker'

// Context
import ModalContext from '../../../context/Modal/Modal.context'
import RegisterContext from '../../../context/Register/Register.context'

// Services
import { postSeries } from '../../../services/register'

// Style
import {
  grid,
  team,
  close,
  trash,
  forms,
  thumb,
  mobile,
  formsLeft,
  formsRight,
  border,
  title,
  hidden,
  header,
  headerNum,
  images,
  imagesContainer,
  button,
  buttons,
  wrapper,
  spinner,
  subtitle,
  noMobile,
  publication,
  chapterGrid,
  descriptions,
  expandedCell,
  submitButton,
} from './style.module.scss'

interface Props {
  succsessModal: ReactNode
  active: boolean
  closeForm: () => void
  openForm: (form: RegisterForm) => void
}

const FormSeries: React.FC<Props> = ({
  succsessModal,
  active,
  closeForm,
  openForm,
}) => {
  const [seriesId, setSeriesId] = useState<string>('')
  const [disable, setDisable] = useState(false)
  const [img, setImg] = useState<string | null>(null)
  const [country, setCountry] = useState<string | null>(null)
  const [status, setStatus] = useState<number | null>(null)

  const [genresArr, setGenresArr] = useState<string[]>([])

  const [comicUUID, setComicUUID] = useState([uuidV4(), uuidV4()])
  const [authors, setAuthors] = useState<string[]>([])

  const [city, setCity] = useState<string | null>(null)
  const [cover, setCover] = useState<string | null>(null)
  const [image, setImage] = useState<string | null>(null)
  const [demAge, setDemAge] = useState<string | null>(null)
  const [demSex, setDemSex] = useState<string | null>(null)
  const [typePub, setTypePub] = useState<string | null>(null)
  const [ageRange, setAgeRange] = useState<number | null>(null)
  const [publisher, setPublisher] = useState<string | null>(null)
  const [distribution, setDistribution] = useState<number | null>(null)

  const { series, updateSeries, types } = useContext(RegisterContext)
  const { openModal } = useContext(ModalContext)

  const methods = useForm<RegisterSeries>({
    mode: 'onTouched',
  })
  const {
    reset,
    control,
    register,
    setFocus,
    setError,
    setValue,
    getValues,
    clearErrors,
    formState: { isValid, errors },
  } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'publications',
  })
  const {
    fields: aFields,
    append: aAppend,
    remove: aRemove,
  } = useFieldArray({
    control,
    name: 'auxAuthors',
  })
  const {
    fields: tFields,
    append: tAppend,
    replace: tRepleace,
  } = useFieldArray({
    control,
    name: 'team',
  })

  useEffect(() => {
    setSeriesId(uuidV4())
    clearErrors()

    // Load local data
    if (window) {
      const localSeries = window.localStorage.getItem('series')
      if (localSeries) {
        const series = JSON.parse(localSeries)
        reset(series, { keepDefaultValues: true })

        setImg(series.series.cover)
        setStatus(series.series.status)
        setCountry(series.series.country)
        setGenresArr([
          series.genres[0].genre,
          series.genres[1].genre,
          series.genres[2].genre,
        ])
        setCity(series.auxChapter.city)
        setImage(series.auxChapter.image)
        setCover(series.auxChapter.cover)
        setAgeRange(series.auxChapter.age)
        setDemSex(series.auxChapter.demSex)
        setDemAge(series.auxChapter.demAge)
        setPublisher(series.auxChapter.publisher)
        setTypePub(series.auxChapter.typePublication)
        setDistribution(series.auxChapter.distribution)
        setAuthors(series.auxAuthors?.map((a: any) => a?.author) ?? [])
      }
    }
    // Auto-save
    const interval = setInterval(() => {
      if (window) {
        window.localStorage.setItem('series', JSON.stringify(getValues()))
      }
    }, 30000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  const addAthor = async (data: any) => {
    data.series.id = seriesId
    for (let key of Object.keys(data.series)) {
      if (data[key] === '') {
        data[key] = undefined
      }
    }
    data.publications = data.publications.map((p: any) => {
      for (let key of Object.keys(p)) {
        if (data[key] === '') {
          data[key] = undefined
        }
      }
      p.type = p.typePublication
      p.id = p.chapterId
      p.series = seriesId
      return p
    })

    data.genres = data.genres.map((g: any) => ({
      id: uuidV4(),
      series: seriesId,
      genre: g.genre,
    }))
    data.auxChapter = undefined
    data.auxAuthors = undefined

    try {
      await postSeries(data)
      await updateSeries()
      reset()
      setImg(null)
      setCity(null)
      setImage(null)
      setCover(null)
      setStatus(null)
      setDemAge(null)
      setDemSex(null)
      setCountry(null)
      setTypePub(null)
      setAgeRange(null)
      setPublisher(null)
      setDistribution(null)
      setAuthors([])
      setGenresArr([])
      openModal(succsessModal)
      setSeriesId(uuidV4())
      setComicUUID([uuidV4(), uuidV4()])
      if (window) {
        window.localStorage.setItem('series', JSON.stringify(getValues()))
      }
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const msg = error.response?.data.message
        openModal(<>Error: {msg}</>)
      }
    } finally {
      setDisable(false)
    }
  }

  const onSubmit = (data: any) => {
    setDisable(true)
    openModal(<Spinner className={spinner} />)
    addAthor(data)
  }

  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>
        Formulario de inscripción de series
        <FontAwesomeIcon icon={faClose} className={close} onClick={closeForm} />
      </div>
      <div className={descriptions}>
        <i>
          Este formulario te permitirá inscribir una nueva serie, es decir un
          comic que tenga más de un capítulo. Si tu cómic tiene un solo capítulo
          usa la opción de <b>inscribir un One-shot.</b> Recuerda que cuanta más
          información nos proporciones, mejor será la impresión que los usuarios
          tendrán de la serie y nos permitirá realizar mejores análisis de la
          situación del cómic colombiano.
        </i>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          <div className={forms}>
            <div className={formsLeft}>
              <div className={subtitle}>Portada de la serie</div>
              <UploadImage
                name={'series.cover'}
                uuid={seriesId}
                folder={'series'}
                external={img}
              />
            </div>
            <div className={formsRight}>
              <div className={subtitle}>Información de la serie</div>
              <div className={grid}>
                <RegisterInput
                  required
                  description="Título de la serie"
                  label="Título"
                  register={register('series.title', {
                    required: { value: true, message: 'Campo requerido' },
                    validate: (v) => {
                      if (v) {
                        //Checking for duplicates
                        const duplicated = series.find(
                          (s) => normalizeText(v) === normalizeText(s.title)
                        )
                        if (duplicated)
                          return 'Ya existe una serie con este título en nuestra base de datos'
                      }
                      return true
                    },
                  })}
                  error={errors?.series?.title?.message}
                />
                <RegisterCountryPicker
                  name="series.country"
                  description="País en la que se produjo la serie. Si es Colombia puedes dejarlo vacío"
                  label="País de producción"
                  register={register('series.country')}
                  error={errors.series?.country?.message}
                  external={country}
                />
                <RegisterStatusPicker
                  name="series.status"
                  description="Estado de la serie. Finalizada, En progreso, etc..."
                  label="Estado de publicación"
                  register={register('series.status')}
                  error={errors.series?.status?.message}
                  external={status}
                />
                <RegisterGenrePicker
                  required
                  name="genres.0.genre"
                  description="Género de la serie. Acción, aventura, fantasía, etc..."
                  label="Género principal"
                  register={register('genres.0.genre')}
                  error={errors.genres && errors.genres[0]?.message}
                  external={genresArr[0]}
                />
                <RegisterGenrePicker
                  name="genres.1.genre"
                  description="Algunos comics pueden tener 2 géneros"
                  label="Género secundario"
                  register={register('genres.1.genre')}
                  error={errors.genres && errors.genres[1]?.message}
                  external={genresArr[1]}
                />
                <RegisterGenrePicker
                  name="genres.2.genre"
                  description="Es muy raro que necesites 3 géneros pero si hace falta, llena este campo"
                  label="Género terciario"
                  register={register('genres.2.genre')}
                  error={errors.genres && errors.genres[2]?.message}
                  external={genresArr[2]}
                />
                <div className={expandedCell}>
                  <div className={subtitle}>Sinopsis</div>
                  <RegisterTextarea
                    description="Breve sinopsis de la serie. Esta es la oportunidad para presentar tu trabajo al público y captar su interés. Asegúrate de transmitir tu pasión y esencia artística de la mejor manera posible, destacando los momentos clave de tu historia y lo que hace único tu enfoque creativo."
                    register={register('series.description', {
                      minLength: {
                        value: 3,
                        message: 'Debe tener mas de 3 caracteres',
                      },
                    })}
                    error={errors.series?.description?.message}
                    center
                    justify
                  />
                </div>
                <div className={subtitle}>Links importantes</div>
                <RegisterInput
                  description="URL a la página de la serie (https://...)"
                  label="Link de lectura"
                  register={register('series.link', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.link?.message}
                />
                <RegisterInput
                  description="URL a la tienda web de la serie (https://...)"
                  label="Tienda virtual"
                  register={register('series.shop', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.shop?.message}
                />
                <RegisterInput
                  description="URL a una vista previa de la serie (https://...)"
                  label="Vista previa"
                  register={register('series.preview', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.preview?.message}
                />
                <RegisterInput
                  description="URL a la página de Patreon de la serie (https://...)"
                  label="Patreon"
                  register={register('series.patreon', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.patreon?.message}
                />
                <RegisterInput
                  description="URL a la página de Vaki de la serie (https://...)"
                  label="Vaki"
                  register={register('series.vaki', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.vaki?.message}
                />
              </div>
            </div>
            <div>
              <div className={chapterGrid}>
                <div id="publications-top" className={subtitle}>
                  Publicaciones
                </div>
                <div className={expandedCell}>
                  <div className={publication}>
                    <div className={headerNum}>#</div>
                    <div className={header}>Cover</div>
                    <div className={header}>Nombre</div>
                    <div className={header}>Tipo</div>
                    <div className={header}>Año</div>
                    <div />
                    {fields.map((f, index) => (
                      <>
                        <div>{f.number}</div>
                        <img className={thumb} src={f.cover ?? ''} />
                        <div>{f.name}</div>
                        <div>{types.find((t) => t.id === f.type)?.name}</div>
                        <div>{f.year}</div>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={trash}
                          onClick={() => {
                            const filteredTeam = tFields.filter(
                              (t) => t.publication !== f.chapterId
                            )
                            tRepleace(filteredTeam)
                            remove(index)
                          }}
                        />
                      </>
                    ))}
                  </div>
                </div>
                <div className={expandedCell}>
                  <div className={descriptions}>
                    <i>
                      Para agregar publicaciones (capítulos, volúmenes o
                      ediciones especiales) a esta tabla, llena la sección
                      llamada <b>Agregar Publicación</b>
                    </i>
                  </div>
                </div>
              </div>
              <div className={[chapterGrid, border].join(' ')}>
                <div className={subtitle}>Agregar Publicación</div>
                <RegisterInput
                  required
                  description="Título de la publicación"
                  label="Título"
                  register={register('auxChapter.name')}
                  error={errors?.auxChapter?.name?.message}
                />
                <RegisterInput
                  required
                  type="number"
                  description="Número de la publicación"
                  label="Número"
                  register={register('auxChapter.number', {
                    required: { value: true, message: 'Campo requerido' },
                  })}
                  error={errors?.auxChapter?.number?.message}
                />
                <RegisterTypePicker
                  required
                  name="auxChapter.typePublication"
                  description="Tipo de la publicación"
                  label="Tipo de capítulo"
                  register={register('auxChapter.typePublication', {
                    validate: (v) => {
                      setTypePub(v ?? '')
                      return true
                    },
                  })}
                  error={errors.auxChapter?.typePublication?.message}
                  external={typePub}
                />
                <RegisterPublisherPicker
                  name="auxChapter.publisher"
                  description="Editorial que financió la publicación. Dejar vació si es independiente"
                  label="Editorial"
                  register={register('auxChapter.publisher')}
                  error={errors.auxChapter?.typePublication?.message}
                  external={publisher}
                />
                <RegisterDistributionPicker
                  name="auxChapter.distribution"
                  description="Impreso, digital o mixto"
                  label="Tipo de distribución"
                  register={register('auxChapter.distribution', {
                    validate: (v) => {
                      setDistribution(v)
                      return true
                    },
                  })}
                  error={errors.auxChapter?.distribution?.message}
                  external={distribution}
                />
                <RegisterInput
                  type="number"
                  description="Año en el que se realizó la primera publicación"
                  label="Año de publicación"
                  register={register('auxChapter.year', {
                    min: {
                      value: 1800,
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                    max: {
                      value: new Date().getFullYear(),
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                  })}
                  error={errors?.auxChapter?.year?.message}
                />
                <RegisterCityPicker
                  name="auxChapter.city"
                  description="Ciudad de Colombia en la que se publicó por primera vez. Si es un comic internacional dejar vació"
                  label="Ciudad de publicación"
                  register={register('auxChapter.city')}
                  error={errors.auxChapter?.city?.message}
                  external={city}
                />
                <RegisterInput
                  description="ISBN de la primera publicación que tenga"
                  label="ISBN/ISSN"
                  register={register('auxChapter.isbn')}
                  error={errors?.auxChapter?.isbn?.message}
                />
                {(distribution === 3 || distribution === 4) && (
                  <>
                    <RegisterInput
                      type="number"
                      description="Copias impresas en total"
                      label="Copias impresas"
                      register={register('auxChapter.copies')}
                      error={errors?.auxChapter?.copies?.message}
                    />
                    <RegisterInput
                      type="number"
                      description="Cuantas ediciones tiene la publicación"
                      label="Numero de ediciones"
                      register={register('auxChapter.reditions')}
                      error={errors?.auxChapter?.reditions?.message}
                    />
                  </>
                )}
                {(typePub === '21568f41-41f9-11ef-9dfe-b8ca3aeea906' ||
                  typePub === '21569aab-41f9-11ef-9dfe-b8ca3aeea906') && (
                  <>
                    {typePub === '21568f41-41f9-11ef-9dfe-b8ca3aeea906' && (
                      <RegisterInput
                        description="Que capítulos incluye tu volumen compilatorio"
                        label="Capítulos"
                        register={register('auxChapter.includes')}
                        error={errors?.auxChapter?.includes?.message}
                      />
                    )}
                    <RegisterInput
                      description="Elementos adicionales de tu volumen compilatorio o edición especial"
                      label="Extras"
                      register={register('auxChapter.extras')}
                      error={errors?.auxChapter?.extras?.message}
                    />
                  </>
                )}
                <div className={imagesContainer}>
                  <div className={images}>
                    <div className={subtitle}>Portada</div>
                    <UploadImage
                      name="auxChapter.cover"
                      uuid={comicUUID[0]}
                      folder="publications"
                      external={cover}
                    />
                  </div>
                  <div className={images}>
                    <div className={subtitle}>Página Interna</div>
                    <UploadImage
                      name="auxChapter.image"
                      uuid={comicUUID[1]}
                      folder="publications"
                      external={image}
                    />
                  </div>
                </div>
                <div className={subtitle}>Sinopsis del Capítulo</div>
                <div className={expandedCell}>
                  <RegisterTextarea
                    description="Breve sinopsis del capítulo. Esta es la oportunidad para presentar tu trabajo al público y captar su interés. Asegúrate de transmitir tu pasión y esencia artística de la mejor manera posible, destacando los momentos clave de tu historia y lo que hace único tu enfoque creativo."
                    register={register('auxChapter.description', {
                      minLength: {
                        value: 3,
                        message: 'Debe tener mas de 3 caracteres',
                      },
                    })}
                    error={errors.auxChapter?.description?.message}
                    center
                    justify
                  />
                </div>
                <div className={subtitle}>Equipo creativo</div>
                <div className={expandedCell}>
                  <div className={team}>
                    <div className={header}>Autor</div>
                    <div className={header}>Rol</div>
                    <div />
                    {aFields.map((a, index) => (
                      <>
                        <RegisterAuthorPicker
                          register={register(`auxAuthors.${index}.author`)}
                          name={`auxAuthors.${index}.author`}
                          external={authors[index]}
                        />
                        <RegisterInput
                          register={register(`auxAuthors.${index}.role`)}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={trash}
                          onClick={() => {
                            aRemove(index)
                          }}
                        />
                      </>
                    ))}
                  </div>
                  <div className={buttons}>
                    <div
                      className={button}
                      onClick={() => {
                        const id = uuidV4()
                        aAppend({
                          id,
                          author: '',
                          publication: '',
                          role: '',
                        })
                      }}
                    >
                      Agregar autor
                    </div>
                    <div
                      className={button}
                      onClick={() => {
                        openForm('Author')
                      }}
                    >
                      Inscribir autor
                    </div>
                  </div>
                </div>
                <div className={subtitle}>Links de acceso</div>
                <div className={noMobile} />
                <RegisterInput
                  description="URL de una página donde se pueda leer directamente la publicación (https://...)"
                  label="Link de lectura"
                  register={register('series.preview', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.auxChapter?.link?.message}
                />
                <RegisterInput
                  description="URL de una página donde se pueda comprar directamente la publicación (https://...)"
                  label="Link de venta"
                  register={register('auxChapter.shop', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.auxChapter?.shop?.message}
                />
                <div className={subtitle}>Público objetivo</div>
                <div className={noMobile} />
                <RegisterDemAgePicker
                  name="auxChapter.demAge"
                  description="Rango de edad de tu público objetivo"
                  label="Edad"
                  register={register('auxChapter.demAge')}
                  error={errors.auxChapter?.demAge?.message}
                  external={demAge}
                />
                <RegisterDemSexPicker
                  name="auxChapter.demSex"
                  description="Género del público objetivo"
                  label="Género"
                  register={register('auxChapter.demSex')}
                  error={errors.auxChapter?.demSex?.message}
                  external={demSex}
                />
                <div />
                <div className={subtitle}>Advertencias</div>
                <RegisterAgePicker
                  name="auxChapter.age"
                  description="Edad mínima sugerida, no es necesariamente la misma del público objetivo"
                  label="Edad mínima"
                  register={register('auxChapter.age')}
                  error={errors.auxChapter?.age?.message}
                  external={ageRange}
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic tiene lenguaje fuerte"
                  label="Lenguaje fuerte"
                  register={register('auxChapter.languageWarn')}
                  error={errors.auxChapter?.languageWarn?.message}
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra actos de violencia, incluso si es moderada"
                  label="Violencia"
                  register={register('auxChapter.violenceWarn')}
                  error={errors.auxChapter?.violenceWarn?.message}
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra el consumo de drogas, cigarillos o alcohol"
                  label="Sustancias"
                  register={register('auxChapter.substancesWarn')}
                  error={errors.auxChapter?.substancesWarn?.message}
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra sangre"
                  label="Sangre"
                  register={register('auxChapter.bloodWarn')}
                  error={errors.auxChapter?.bloodWarn?.message}
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra desnudos"
                  label="Desnudos"
                  register={register('auxChapter.nudeWarn')}
                  error={errors.auxChapter?.nudeWarn?.message}
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra actos sexuales"
                  label="Sexo"
                  register={register('auxChapter.sexWarn')}
                  error={errors.auxChapter?.sexWarn?.message}
                  center
                  justify
                />
                <div className={mobile}/>
                <div
                  className={button}
                  onClick={() => {
                    if (
                      getValues('auxChapter.name').trim() &&
                      getValues('auxChapter.number') &&
                      getValues('auxChapter.typePublication')
                    ) {
                      const id = uuidV4()
                      setComicUUID([uuidV4(), uuidV4()])
                      append({
                        id,
                        chapterId: id,
                        name: getValues('auxChapter.name'),
                        series: '',
                        type: getValues('auxChapter.typePublication') ?? '',
                        number: getValues('auxChapter.number'),
                        cover: getValues('auxChapter.cover'),
                        image: getValues('auxChapter.image'),
                        year: getValues('auxChapter.year'),
                        publisher: getValues('auxChapter.publisher'),
                        age: getValues('auxChapter.age'),
                        city: getValues('auxChapter.city'),
                        isbn: getValues('auxChapter.isbn'),
                        distribution: getValues('auxChapter.distribution'),
                        copies: getValues('auxChapter.copies'),
                        reditions: getValues('auxChapter.reditions'),
                        description: getValues('auxChapter.description'),
                        includes: getValues('auxChapter.includes'),
                        extras: getValues('auxChapter.extras'),
                        link: getValues('auxChapter.link'),
                        shop: getValues('auxChapter.shop'),
                        emailShop: getValues('auxChapter.emailShop'),
                        languageWarn: getValues('auxChapter.languageWarn'),
                        violenceWarn: getValues('auxChapter.violenceWarn'),
                        substancesWarn: getValues('auxChapter.substancesWarn'),
                        bloodWarn: getValues('auxChapter.bloodWarn'),
                        nudeWarn: getValues('auxChapter.nudeWarn'),
                        sexWarn: getValues('auxChapter.sexWarn'),
                        demAge: getValues('auxChapter.demAge'),
                        demSex: getValues('auxChapter.demSex'),
                      })
                      aFields.forEach((a, i) => {
                        tAppend({ ...a, publication: id })
                      })
                      setValue('auxChapter.name', '')
                      clearErrors('auxChapter.name')
                      setValue(
                        'auxChapter.number',
                        Math.floor(Number(getValues('auxChapter.number')) + 1)
                      )
                      document
                        .getElementById('publications-top')
                        ?.scrollIntoView()
                    } else {
                      setError('auxChapter.name', {
                        message: 'Campo requerido',
                      })
                      setFocus('auxChapter.name')
                    }
                  }}
                >
                  Agregar publicación
                </div>
              </div>
            </div>
          </div>
          <input
            type="submit"
            disabled={!isValid || disable}
            className={submitButton}
            value="Inscibir"
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default FormSeries
