// extracted by mini-css-extract-plugin
export var errorLabel = "style-module--error-label--fecdd";
export var ghost = "style-module--ghost--80ad4";
export var input = "style-module--input--e0047";
export var inputDescription = "style-module--input-description--66a2e";
export var inputLabel = "style-module--input-label--7ae96";
export var inputWrapper = "style-module--input-wrapper--d5db7";
export var long = "style-module--long--510c0";
export var visible = "style-module--visible--95a2c";
export var visibleElement = "style-module--visible-element--baa41";
export var wrapper = "style-module--wrapper--21ed1";