import React, { ReactNode } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

// Style
import {
  long,
  input,
  inputLabel,
  inputDescription,
  wrapper,
  errorLabel,
} from './style.module.scss'

interface Props {
  label?: string | ReactNode
  description?: string | ReactNode
  register: UseFormRegisterReturn
  error?: string
  justify?: boolean
  type?: 'number'
  required?: boolean
}

const RegisterInput: React.FC<Props> = ({
  description,
  label,
  register,
  error,
  type,
  justify,
  required,
}) => {
  return (
    <div className={wrapper}>
      <div className={inputLabel}>
        {label}
        {required && ' *'}
      </div>
      <input {...register} className={input} type={type} />
      <div className={[inputDescription, justify ? long : undefined].join(' ')}>
        {error ? <span className={errorLabel}>{error}</span> : description}
      </div>
    </div>
  )
}

export default RegisterInput
