// extracted by mini-css-extract-plugin
export var errorLabel = "style-module--error-label--7de5a";
export var ghost = "style-module--ghost--5399a";
export var input = "style-module--input--c0292";
export var inputDescription = "style-module--input-description--e8815";
export var inputLabel = "style-module--input-label--32af7";
export var inputWrapper = "style-module--input-wrapper--d7daf";
export var long = "style-module--long--f78fa";
export var visible = "style-module--visible--da3bb";
export var visibleElement = "style-module--visible-element--2aefb";
export var wrapper = "style-module--wrapper--91aea";