import React from 'react'

// Style
import {
  title,
  wrapper,
  button,
  button0,
  button1,
  button2,
  button3,
  buttonContainer,
} from './style.module.scss'

type SuccessButton = {
  label: string
  onClick?: () => void
}

interface Props {
  buttons: readonly [
    SuccessButton?,
    SuccessButton?,
    SuccessButton?,
    SuccessButton?
  ]
}

const SuccsessModal: React.FC<Props> = ({ buttons }) => {
  const styleArr = [button0, button1, button2, button3]
  return (
    <div className={wrapper}>
      <div className={title}>Se ha completado el registro con exito</div>
      <div className={buttonContainer}>
        {buttons.map((b, i) => (
          <div
            className={`${button} ${
              i == buttons.length - 1 ? styleArr[3] : styleArr[i]
            }`}
            onClick={b?.onClick}
          >
            {b?.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export default SuccsessModal
