import {
  faBookBookmark,
  faBookmark,
  faBookOpen,
  faDragon,
  faNewspaper,
  faPenFancy,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { RegisterForm } from '../../templates/Register'

// Style
import {
  grid,
  title,
  button,
  buttonIcon,
  buttonLabel,
  hidden,
  wrapper,
  descriptions,
  participants,
} from './style.module.scss'

interface Props {
  active: boolean
  openForm: (form: RegisterForm) => void
}

const RegisterHome: React.FC<Props> = ({ active, openForm }) => {
  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>Formulario de inscripción</div>
      <div className={descriptions}>
        <i>
          Este formulario te permitirá inscribir un nuevo autor, ilustrador,
          editor o cualquier otro actor del sector del cómic colombiano.
          Recuerda que cuanta más información nos proporciones, mejor podremos
          ayudar, ya que esto mejorará la impresión que los usuarios tendrán del
          autor y nos permitirá realizar mejores análisis de la situación del
          cómic colombiano
        </i>
      </div>
      <div className={title}>Pasos</div>
      <div className={grid}>
        <div className={button} onClick={() => openForm('Author')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faPenFancy} />
          </div>
          <div className={buttonLabel}>1. Inscribir Autores</div>
        </div>
        <div className={button} onClick={() => openForm('Publisher')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faNewspaper} />
          </div>
          <div className={buttonLabel}>
            2. Inscribir Editoriales
            <br />
            (si aplica)
          </div>
        </div>
        <div className={button} onClick={() => openForm('OneShot')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookOpen} />
          </div>
          <div className={buttonLabel}>3. Inscribir una Obra</div>
        </div>
        {/* <div className={button} onClick={() => openForm('Series')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookBookmark} />
          </div>
          <div className={buttonLabel}>3.b Inscribir Series</div>
        </div> */}
        {/* <div className={button} onClick={() => openForm('SOFA2024')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faDragon} />
          </div>
          <div className={buttonLabel}>
            4. Finalizar la inscripción para la actividad de SOFA 2024
          </div>
        </div> */}
        {/* <div className={button} onClick={() => openForm('Chapter')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookmark} />
          </div>
          <div className={buttonLabel}>
            5. Agregar Capítulo de una Series que ya esta inscrita
          </div>
        </div> */}
      </div>
      <div className={title}>Participan</div>
      <img src="https://assets.comiccolombiano.com/register/logos.jpg" />
    </div>
  )
}

export default RegisterHome
