// extracted by mini-css-extract-plugin
export var errorLabel = "style-module--error-label--0270d";
export var ghost = "style-module--ghost--ee5d7";
export var input = "style-module--input--fc1e6";
export var inputDescription = "style-module--input-description--a9ec1";
export var inputLabel = "style-module--input-label--0ee04";
export var inputWrapper = "style-module--input-wrapper--6a09b";
export var long = "style-module--long--f735f";
export var visible = "style-module--visible--13619";
export var visibleElement = "style-module--visible-element--9e13d";
export var wrapper = "style-module--wrapper--e70a3";