// extracted by mini-css-extract-plugin
export var errorLabel = "style-module--error-label--40ad6";
export var ghost = "style-module--ghost--23206";
export var input = "style-module--input--97055";
export var inputDescription = "style-module--input-description--3332d";
export var inputLabel = "style-module--input-label--617e5";
export var inputWrapper = "style-module--input-wrapper--b1e07";
export var long = "style-module--long--e0c88";
export var visible = "style-module--visible--1ae02";
export var visibleElement = "style-module--visible-element--7b325";
export var wrapper = "style-module--wrapper--12cf3";