import React, { useContext, useEffect, useState, ReactNode } from 'react'

import { v4 as uuidV4 } from 'uuid'
import { AxiosError } from 'axios'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faTrash } from '@fortawesome/free-solid-svg-icons'

// Components
import Spinner from '../../atoms/Spinner'
import UploadImage from '../../atoms/UploadImage'
import { normalizeText } from '../../../utils/utils'
import RegisterInput from '../../atoms/RegisterInput'
import RegisterCheck from '../../atoms/RegisterCheck'
import { RegisterForm } from '../../templates/Register'
import { RegisterSeries } from '../../../services/types'
import RegisterTextarea from '../../atoms/RegisterTextArea'
import RegisterAgePicker from '../../atoms/RegisterAgePicker'
import RegisterCityPicker from '../../atoms/RegisterCityPicker'
import RegisterGenrePicker from '../../atoms/RegisterGenrePicker'
import RegisterDemSexPicker from '../../atoms/RegisterDemSexPicker'
import RegisterAuthorPicker from '../../atoms/RegisterAuthorPicker'
import RegisterDemAgePicker from '../../atoms/RegisterDemAgePicker'
import RegisterStatusPicker from '../../atoms/RegisterStatusPicker'
import RegisterCountryPicker from '../../atoms/RegisterCountryPicker'
import RegisterPublisherPicker from '../../atoms/RegisterPublisherPicker'
import RegisterDistributionPicker from '../../atoms/RegisterDistributionPicker'

// Context
import ModalContext from '../../../context/Modal/Modal.context'
import RegisterContext from '../../../context/Register/Register.context'

// Services
import { postSeries } from '../../../services/register'

// Style
import {
  grid,
  team,
  close,
  trash,
  forms,
  formsLeft,
  formsRight,
  title,
  hidden,
  header,
  button,
  buttons,
  wrapper,
  spinner,
  subtitle,
  descriptions,
  expandedCell,
  submitButton,
} from './style.module.scss'
interface Props {
  succsessModal: ReactNode
  active: boolean
  closeForm: () => void
  openForm: (form: RegisterForm) => void
}

const FormOneshot: React.FC<Props> = ({
  succsessModal,
  active,
  closeForm,
  openForm,
}) => {
  const [seriesId, setSeriesId] = useState<string>('')
  const [disable, setDisable] = useState(false)
  const [img, setImg] = useState<string | null>(null)
  const [country, setCountry] = useState<string | null>(null)
  const [status, setStatus] = useState<number | null>(null)

  const [genresArr, setGenresArr] = useState<string[]>([])

  const [comicUUID, setComicUUID] = useState([uuidV4(), uuidV4()])
  const [authors, setAuthors] = useState<string[]>([])

  const [city, setCity] = useState<string | null>(null)
  const [image, setImage] = useState<string | null>(null)
  const [demAge, setDemAge] = useState<string | null>(null)
  const [demSex, setDemSex] = useState<string | null>(null)
  const [ageRange, setAgeRange] = useState<number | null>(null)
  const [publisher, setPublisher] = useState<string | null>(null)
  const [distribution, setDistribution] = useState<number | null>(null)

  const { series, updateSeries, types } = useContext(RegisterContext)
  const { openModal } = useContext(ModalContext)

  const methods = useForm<RegisterSeries>({
    mode: 'onTouched',
  })
  const {
    reset,
    control,
    register,
    getValues,
    clearErrors,
    formState: { isValid, errors },
  } = methods

  const {
    fields: tFields,
    remove: tRemove,
    append: tAppend,
  } = useFieldArray({
    control,
    name: 'team',
  })

  useEffect(() => {
    setSeriesId(uuidV4())
    clearErrors()

    // Load local data
    if (window) {
      const localSeries = window.localStorage.getItem('oneshot')
      if (localSeries) {
        const series = JSON.parse(localSeries)
        reset(series, { keepDefaultValues: true })

        setImg(series.series.cover)
        setStatus(series.series.status)
        setCountry(series.series.country)
        setGenresArr([
          series.genres[0].genre,
          series.genres[1].genre,
          series.genres[2].genre,
        ])
        setCity(series.publications[0].city)
        setImage(series.publications[0].image)
        setAgeRange(series.publications[0].age)
        setDemSex(series.publications[0].demSex)
        setDemAge(series.publications[0].demAge)
        setPublisher(series.publications[0].publisher)
        setDistribution(series.publications[0].distribution)
        setAuthors(series.team?.map((a: any) => a?.author) ?? [])
      }
    }
    // Auto-save
    const interval = setInterval(() => {
      if (window) {
        window.localStorage.setItem('oneshot', JSON.stringify(getValues()))
      }
    }, 30000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  const addOneShot = async (data: any) => {
    const pubId = uuidV4()
    data.series.id = seriesId
    console.log(data.publications)

    for (let key of Object.keys(data.series)) {
      if (data.series[key] === '') {
        data.series[key] = undefined
      }
    }

    data.publications = data.publications.map((p: any, i: number) => {
      for (let key of Object.keys(p)) {
        if (data.publications[i][key] === '') {
          data.publications[i][key] = undefined
        }
      }
      p.id = pubId
      p.series = seriesId
      p.name = data.series.title
      p.type = types.find((t) => t.name === 'One-Shot')?.id
      p.number = 1
      p.cover = data.series.cover
      p.description = data.series.description
      return p
    })
    console.log(data.publications)

    data.genres = data.genres
      .filter((g: any) => g.genre)
      .map((g: any) => ({
        id: uuidV4(),
        series: seriesId,
        genre: g.genre,
      }))

    data.team = data.team.map((t: any) => ({ ...t, publication: pubId }))

    try {
      const result = await postSeries(data)
      await updateSeries()
      reset()
      setImg(null)
      setCity(null)
      setImage(null)
      setStatus(null)
      setDemAge(null)
      setDemSex(null)
      setCountry(null)
      setAgeRange(null)
      setPublisher(null)
      setDistribution(null)
      setAuthors([])
      setGenresArr([])
      openModal(succsessModal)
      setSeriesId(uuidV4())
      setComicUUID([uuidV4(), uuidV4()])
      if (window) {
        window.localStorage.setItem('oneshot', JSON.stringify(getValues()))
      }
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const msg = error.response?.data.message
        openModal(<>Error: {msg}</>)
      }
    } finally {
      setDisable(false)
    }
  }

  const onSubmit = (data: any) => {
    setDisable(true)
    openModal(<Spinner className={spinner} />)
    addOneShot(data)
  }

  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>
        Formulario de inscripción de One shots
        <FontAwesomeIcon icon={faClose} className={close} onClick={closeForm} />
      </div>
      <div className={descriptions}>
        <i>
          Este formulario te permitirá inscribir un One shot, es decir un comic
          que tiene un solo capítulo. Si tu cómic tiene más de un capítulo usa
          la opción de <b>Inscribir una serie</b>. Recuerda que cuanta más
          información nos proporciones, mejor será la impresión que los usuarios
          tendrán de la serie y nos permitirá realizar mejores análisis de la
          situación del cómic colombiano.
        </i>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          <div className={forms}>
            <div className={formsLeft}>
              <div className={subtitle}>Portada del one shot</div>
              <UploadImage
                name={'series.cover'}
                uuid={seriesId}
                folder={'series'}
                external={img}
              />
              <div className={subtitle}>Página Interna</div>
              <UploadImage
                name="publications.0.image"
                uuid={comicUUID[1]}
                folder="publications"
                external={image}
              />
            </div>
            <div className={formsRight}>
              <div className={subtitle}>Información de la serie</div>
              <div className={grid}>
                <RegisterInput
                  required
                  description="Título de la serie"
                  label="Título"
                  register={register('series.title', {
                    required: { value: true, message: 'Campo requerido' },
                    validate: (v) => {
                      if (v) {
                        //Checking for duplicates
                        const duplicated = series.find(
                          (s) => normalizeText(v) === normalizeText(s.title)
                        )
                        if (duplicated)
                          return 'Ya existe una serie con este título en nuestra base de datos'
                      }
                      return true
                    },
                  })}
                  error={errors?.series?.title?.message}
                />
                <RegisterCountryPicker
                  name="series.country"
                  description="País en la que se produjo la serie. Si es Colombia puedes dejarlo vacío"
                  label="País de producción"
                  register={register('series.country')}
                  error={errors.series?.country?.message}
                  external={country}
                />
                <RegisterStatusPicker
                  name="series.status"
                  description="Estado de la serie. Finalizada, En progreso, etc..."
                  label="Estado de publicación"
                  register={register('series.status')}
                  error={errors.series?.status?.message}
                  external={status}
                />
                <RegisterGenrePicker
                  required
                  name="genres.0.genre"
                  description="Género de la serie. Acción, aventura, fantasía, etc..."
                  label="Género principal"
                  register={register('genres.0.genre', {
                    required: { value: true, message: 'Campo requerido' },
                  })}
                  error={errors.genres && errors.genres[0]?.message}
                  external={genresArr[0]}
                />
                <RegisterGenrePicker
                  name="genres.1.genre"
                  description="Algunos comics pueden tener 2 géneros"
                  label="Género secundario"
                  register={register('genres.1.genre')}
                  error={errors.genres && errors.genres[1]?.message}
                  external={genresArr[1]}
                />
                <RegisterGenrePicker
                  name="genres.2.genre"
                  description="Es muy raro que necesites 3 géneros pero si hace falta, llena este campo"
                  label="Género terciario"
                  register={register('genres.2.genre')}
                  error={errors.genres && errors.genres[2]?.message}
                  external={genresArr[2]}
                />
                <div className={expandedCell}>
                  <div className={subtitle}>Sinopsis</div>
                  <RegisterTextarea
                    description="Breve sinopsis de la serie. Esta es la oportunidad para presentar tu trabajo al público y captar su interés. Asegúrate de transmitir tu pasión y esencia artística de la mejor manera posible, destacando los momentos clave de tu historia y lo que hace único tu enfoque creativo."
                    register={register('series.description', {
                      minLength: {
                        value: 3,
                        message: 'Debe tener mas de 3 caracteres',
                      },
                    })}
                    error={errors.series?.description?.message}
                    center
                    justify
                  />
                </div>
                <div className={subtitle}>Información Editorial</div>
                <RegisterPublisherPicker
                  name="publications.0.publisher"
                  description="Editorial que financió la publicación. Dejar vació si es independiente"
                  label="Editorial"
                  register={register('publications.0.publisher')}
                  error={
                    errors.publications &&
                    errors.publications[0]?.publisher?.message
                  }
                  external={publisher}
                />
                <RegisterDistributionPicker
                  name="publications.0.distribution"
                  description="Impreso, digital o mixto"
                  label="Tipo de distribución"
                  register={register('publications.0.distribution', {
                    validate: (v) => {
                      setDistribution(v)
                      return true
                    },
                  })}
                  error={
                    errors.publications &&
                    errors.publications[0]?.distribution?.message
                  }
                  external={distribution}
                />
                <RegisterInput
                  type="number"
                  description="Año en el que se realizó la primera publicación"
                  label="Año de publicación"
                  register={register('publications.0.year', {
                    min: {
                      value: 1800,
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                    max: {
                      value: new Date().getFullYear(),
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                  })}
                  error={
                    errors.publications && errors.publications[0]?.year?.message
                  }
                />
                <RegisterCityPicker
                  name="publications.0.city"
                  description="Ciudad de Colombia en la que se publicó por primera vez. Si es un comic internacional dejar vació"
                  label="Ciudad de publicación"
                  register={register('publications.0.city')}
                  error={
                    errors.publications && errors.publications[0]?.city?.message
                  }
                  external={city}
                />
                <RegisterInput
                  description="ISBN de la primera publicación que tenga"
                  label="ISBN/ISSN"
                  register={register('publications.0.isbn')}
                  error={
                    errors.publications && errors.publications[0]?.isbn?.message
                  }
                />
                {(distribution === 3 || distribution === 4) && (
                  <>
                    <RegisterInput
                      type="number"
                      description="Copias impresas en total"
                      label="Copias impresas"
                      register={register('publications.0.copies')}
                      error={
                        errors.publications &&
                        errors.publications[0]?.copies?.message
                      }
                    />
                    <RegisterInput
                      type="number"
                      description="Cuantas ediciones tiene la publicación"
                      label="Numero de ediciones"
                      register={register('publications.0.reditions')}
                      error={
                        errors.publications &&
                        errors.publications[0]?.reditions?.message
                      }
                    />
                  </>
                )}
                <div className={expandedCell}>
                  <i>Si no encuntras la editorial puedes inscribirla aqui</i>
                  <div
                    className={button}
                    onClick={() => {
                      openForm('Publisher')
                    }}
                  >
                    Inscribir editorial
                  </div>
                </div>

                <div className={subtitle}>Equipo creativo</div>
                <div className={expandedCell}>
                  <div className={team}>
                    <div className={header}>Autor</div>
                    <div className={header}>Rol</div>
                    <div />
                    {tFields.map((a, index) => (
                      <>
                        <RegisterAuthorPicker
                          register={register(`team.${index}.author`)}
                          name={`team.${index}.author`}
                          external={authors[index]}
                        />
                        <RegisterInput
                          register={register(`team.${index}.role`)}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={trash}
                          onClick={() => {
                            tRemove(index)
                          }}
                        />
                      </>
                    ))}
                  </div>
                  <div className={buttons}>
                    <div
                      className={button}
                      onClick={() => {
                        const id = uuidV4()
                        tAppend({
                          id,
                          author: '',
                          publication: '',
                          role: '',
                        })
                      }}
                    >
                      Agregar autor
                    </div>
                    <div
                      className={button}
                      onClick={() => {
                        openForm('Author')
                      }}
                    >
                      Inscribir autor
                    </div>
                  </div>
                </div>
                <div className={subtitle}>Público objetivo</div>
                <RegisterDemAgePicker
                  name="publications.0.demAge"
                  description="Rango de edad de tu público objetivo"
                  label="Edad"
                  register={register('publications.0.demAge')}
                  error={
                    errors.publications &&
                    errors.publications[0]?.demAge?.message
                  }
                  external={demAge}
                />
                <RegisterDemSexPicker
                  name="publications.0.demSex"
                  description="Género del público objetivo"
                  label="Género"
                  register={register('publications.0.demSex')}
                  error={
                    errors.publications &&
                    errors.publications[0]?.demSex?.message
                  }
                  external={demSex}
                />
                <div />
                <div className={subtitle}>Advertencias</div>
                <RegisterAgePicker
                  name="publications.0.age"
                  description="Edad mínima sugerida, no es necesariamente la misma del público objetivo"
                  label="Edad mínima"
                  register={register('publications.0.age')}
                  error={
                    errors.publications && errors.publications[0]?.age?.message
                  }
                  external={ageRange}
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic tiene lenguaje fuerte"
                  label="Lenguaje fuerte"
                  register={register('publications.0.languageWarn')}
                  error={
                    errors.publications &&
                    errors.publications[0]?.languageWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra actos de violencia, incluso si es moderada"
                  label="Violencia"
                  register={register('publications.0.violenceWarn')}
                  error={
                    errors.publications &&
                    errors.publications[0]?.violenceWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra el consumo de drogas, cigarillos o alcohol"
                  label="Sustancias"
                  register={register('publications.0.substancesWarn')}
                  error={
                    errors.publications &&
                    errors.publications[0]?.substancesWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra sangre"
                  label="Sangre"
                  register={register('publications.0.bloodWarn')}
                  error={
                    errors.publications &&
                    errors.publications[0]?.bloodWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra desnudos"
                  label="Desnudos"
                  register={register('publications.0.nudeWarn')}
                  error={
                    errors.publications &&
                    errors.publications[0]?.nudeWarn?.message
                  }
                  center
                  justify
                />
                <RegisterCheck
                  description="Activa esta casilla si tu cómic muestra actos sexuales"
                  label="Sexo"
                  register={register('publications.0.sexWarn')}
                  error={
                    errors.publications &&
                    errors.publications[0]?.sexWarn?.message
                  }
                  center
                  justify
                />

                <div className={subtitle}>Links importantes</div>
                <RegisterInput
                  description="URL a la página de la serie (https://...)"
                  label="Link de lectura"
                  register={register('series.link', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.link?.message}
                />
                <RegisterInput
                  description="URL a la tienda web de la serie (https://...)"
                  label="Tienda virtual"
                  register={register('series.shop', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.shop?.message}
                />
                <RegisterInput
                  description="URL a una vista previa de la serie (https://...)"
                  label="Vista previa"
                  register={register('series.preview', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.preview?.message}
                />
                <RegisterInput
                  description="URL a la página de Patreon de la serie (https://...)"
                  label="Patreon"
                  register={register('series.patreon', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.patreon?.message}
                />
                <RegisterInput
                  description="URL a la página de Vaki de la serie (https://...)"
                  label="Vaki"
                  register={register('series.vaki', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.vaki?.message}
                />
              </div>
            </div>
          </div>
          <input
            type="submit"
            disabled={!isValid || disable}
            className={submitButton}
            value="Inscibir"
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default FormOneshot
