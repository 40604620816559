import React, { ReactNode } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

// Style
import {
  long,
  input,
  inputLabel,
  inputDescription,
  wrapper,
  centered,
  errorLabel,
} from './style.module.scss'

interface Props {
  label?: string | ReactNode
  description?: string | ReactNode
  register: UseFormRegisterReturn
  error?: string
  justify?: boolean
  center?: boolean
  required?: boolean
}

const RegisterTextarea: React.FC<Props> = ({
  description,
  label,
  register,
  error,
  center,
  justify,
  required,
}) => {
  return (
    <div className={[wrapper, center ? centered : undefined].join(' ')}>
      <div className={inputLabel}>{label}
      {required && ' *'}</div>
      <textarea {...register} className={input} />
      <div className={[inputDescription, justify ? long : undefined].join(' ')}>
        {error ? <span className={errorLabel}>{error}</span> : description}
      </div>
    </div>
  )
}

export default RegisterTextarea
