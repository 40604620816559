import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form'

// Components
import { normalizeText } from '../../../utils/utils'

// Context
import RegisterContext from '../../../context/Register/Register.context'

// Style
import {
  long,
  ghost,
  input,
  inputLabel,
  inputWrapper,
  inputDescription,
  wrapper,
  visible,
  visibleElement,
  errorLabel,
} from './style.module.scss'

interface Props {
  name: string
  register: UseFormRegisterReturn
  error?: string
  justify?: boolean
  label?: string | ReactNode
  description?: string | ReactNode
  external?: string | null
}

type DemAgeElement = {
  id: string
  text: string
}

const RegisterDemAgePicker: React.FC<Props> = ({
  name,
  label,
  error,
  justify,
  external,
  register,
  description,
}) => {
  const { demAges } = useContext(RegisterContext)

  const [list, setList] = useState<DemAgeElement[]>()
  const [filter, setFilter] = useState<DemAgeElement[]>()

  const ref = useRef<HTMLInputElement | null>(null)
  const { setValue } = useFormContext()

  const loadDemAges = async () => {
    const genreOptions = demAges.map((c) => {
      return { id: c.id, text: `${c.name} (${c.start}-${c.end})` }
    })
    .sort((a, b) => {
      const aStart = demAges.find((d) => a.id === d.id)?.start ?? 0
      const bStart = demAges.find((d) => b.id === d.id)?.start ?? 0
      return aStart - bStart
    })
    setList(genreOptions)
  }

  const filterDemAge = (query: string) => {
    const filtered = list
      ?.filter((c) => normalizeText(c.text).includes(normalizeText(query)))
      .sort((a, b) => {
        const aStart = demAges.find((d) => a.id === d.id)?.start ?? 0
        const bStart = demAges.find((d) => b.id === d.id)?.start ?? 0
        return aStart - bStart
      })
    setFilter(filtered)
  }

  const setDemAge = (text: string, id: string) => {
    if (ref.current) ref.current.value = text
    setValue(name, id)
  }

  useEffect(() => {
    loadDemAges()
  }, [demAges])

  useEffect(() => {
    setFilter(list)
  }, [list])

  useEffect(() => {
    if (typeof external !== 'undefined' && ref.current) {
      if (external) {
        const genre = list?.find((c) => c.id === external)
        if (genre) ref.current.value = genre?.text
        else ref.current.value = ''
      } else ref.current.value = ''
    }
  }, [external, list])

  return (
    <div className={wrapper}>
      <div className={inputLabel}>{label}</div>
      <div className={inputWrapper}>
        <input
          className={input}
          onChange={(e) => filterDemAge(e.target.value)}
          ref={ref}
        />
        <div className={visible}>
          {filter?.map((c) => (
            <div
              key={c.id}
              className={visibleElement}
              onClick={() => setDemAge(c.text, c.id)}
            >
              {c.text}
            </div>
          ))}
        </div>
      </div>
      <div className={[inputDescription, justify ? long : undefined].join(' ')}>
        {error ? <span className={errorLabel}>{error}</span> : description}
      </div>
      <input {...register} className={ghost} />
    </div>
  )
}

export default RegisterDemAgePicker
