import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useFormContext } from 'react-hook-form'
import {
  faCloudArrowUp,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Components
import { uploadImage } from '../../../services/register'

// Style
import {
  thumb,
  upload,
  uploadGhost,
  uploadThumb,
  uploadTrash,
  spinner,
  spinnerAnimation,
  description,
  wrapper,
} from './style.module.scss'

type Statuses = 'empty' | 'loading' | 'uploaded'

interface Props {
  name: string
  uuid: string
  folder: string
  external?: string | null
}

const UploadImage: React.FC<Props> = ({ name, uuid, folder, external }) => {
  const [status, setStatus] = useState<Statuses>('empty')
  const [img, setImg] = useState<string | null>(null)
  const ref = useRef<HTMLInputElement | null>(null)
  const random = uuidV4()
  const { register, setValue } = useFormContext()

  const uploadImg = async (files: FileList | null) => {
    let retries = 0
    while (retries < 3) {
      try {
        if (files) {
          const data = new FormData()
          data.append('name', uuid)
          data.append('folder', folder)
          data.append('file', files[0])
          const response = await uploadImage(data)
          setImg(response.data.result)
          setStatus('uploaded')
          retries = 10
        }
      } catch (error) {
        retries++
        console.error(error)
      }
    }
    if (retries < 10) resetFile()
  }

  const resetFile = () => {
    if (ref.current) {
      ref.current.value = ''
      ref.current.type = 'text'
      ref.current.type = 'file'
      setStatus('empty')
      setImg(null)
    }
  }

  useEffect(() => {
    setValue(name, img)
  }, [img])

  useEffect(() => {
    if (typeof external !== 'undefined') {
      setImg(external)
      if (external) {
        setStatus('uploaded')
      } else {
        setStatus('empty')
      }
    }
  }, [external])

  return (
    <>
      <div className={wrapper}>
        {status === 'empty' && (
          <label htmlFor={`file-input-${random}`} className={upload}>
            <FontAwesomeIcon icon={faCloudArrowUp} />
          </label>
        )}
        {status === 'loading' && (
          <div className={spinner}>
            <FontAwesomeIcon icon={faSpinner} className={spinnerAnimation} />
          </div>
        )}
        {status === 'uploaded' && (
          <div className={thumb}>
            <img className={uploadThumb} src={img ?? ''} />
            <div className={uploadTrash} onClick={resetFile}>
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </div>
        )}
        <div className={description}>
          <b>ATENCIÓN</b> <br />
          <i>
            La imagén debe estar en formato JPG y pesar menos de 4MB. <br />{' '}
            Sugerimos también que no tenga más de 500px de ancho
          </i>
        </div>
        <input
          id={`file-input-${random}`}
          type="file"
          className={uploadGhost}
          onChange={(e) => {
            setStatus('loading')
            uploadImg(e.target.files)
          }}
          ref={ref}
          accept='image/jpeg'
        />
      </div>

      <input {...register(name)} className={uploadGhost} />
    </>
  )
}

export default UploadImage
